import { Auth } from "aws-amplify"
import { reset } from "."
import api from "../api"
import { ROUTES_NAMES } from "../constants"
import { queryClient } from "../queryClient"
import analytics from "../services/analytics"
import {
  reset as resetErrorTracking,
  setup as setupErrorTracking
} from "../services/errorTracking"
import { loadProfile } from "./profile"
import {
  googleFederatedSignup,
  loginConfirmCode,
  microsoftFederatedSignup
} from "./signup"

const {
  acceptTos: { route: acceptTosRoute }
} = ROUTES_NAMES

export const LOGIN_REQUESTED = "auth/LOGIN_REQUESTED"
export const LOGIN = "auth/LOGIN"
export const RESET = "app/RESET"
export const LOGIN_FAILED = "auth/LOGIN_FAILED"
export const SET_LOGIN_UI = "auth/SET_LOGIN_UI"
export const UNSET_LOGIN_UI = "/auth/UNSET_LOGIN_UI"
export const SET_FEDERATED_INFO = "auth/SET_FEDERATED_INFO"
export const SET_FEDERATED_TIMEOUT = "auth/SET_FEDERATED_TIMEOUT"
export const SET_AUTH_ERROR = "auth/SET_AUTH_ERROR"

const initialState = {
  email: null,
  error: null,
  name: null,
  isLoggingIn: false,
  loggedIn: null,
  groups: [],
  loginUI: false,
  federatedInfo: null,
  timeout: null
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN_REQUESTED:
      return {
        ...state,
        ...payload,
        error: null,
        isLoggingIn: true
      }
    case LOGIN:
      return {
        ...state,
        email: payload.email,
        error: null,
        name: payload.name,
        groups: payload.groups || [],
        loggedIn: true,
        isLoggingIn: false,
        loginUI: false,
        firstLoginEver: payload.firstLoginEver,
        loginState: payload.loginState
      }
    case LOGIN_FAILED:
      return {
        ...state,
        error: payload || null,
        name: null,
        groups: [],
        isLoggingIn: false,
        loggedIn: false
      }
    case SET_LOGIN_UI:
      return {
        ...state,
        error: null,
        loginUI: true
      }
    case UNSET_LOGIN_UI:
      return {
        ...state,
        email: null,
        error: null,
        loginUI: false
      }
    case SET_FEDERATED_INFO: {
      return {
        ...state,
        federatedInfo: payload
      }
    }
    case SET_FEDERATED_TIMEOUT: {
      return {
        ...state,
        timeout: payload
      }
    }
    case SET_AUTH_ERROR: {
      return {
        ...state,
        error: payload.error,
        email: payload.email
      }
    }
    default:
      return state
  }
}

// this function happen as listener if the user exist or not and sign up a federated user
export const loginCurrentUser =
  (type, loginUser, team = null, { navigate }) =>
    async (dispatch) => {
      dispatch({ type: LOGIN_REQUESTED })
      const queryParameters = new URLSearchParams(window.location.search)
      const emailParam = queryParameters.get("email")
      const codeParam = queryParameters.get("code")
      try {
        const creds = await Auth.currentUserCredentials()
        if (creds.name === "NotAuthorizedException") {
          throw new Error("No Current User or Token Expired")
        }
        if (!creds) {
          throw new Error("No Current User or Token Expired")
        }

        // Now loading the profile before verification, to prevent users without profile
        const profile = await dispatch(loadProfile())
        const userInfo = await Auth.currentUserInfo()

        if (typeof userInfo?.attributes?.email_verified !== "undefined") {
          const {
            attributes: { email_verified }
          } = userInfo
          if (!email_verified) {
            throw new Error(`User is not confirmed.`)
          }
        }

        if (!profile) {
          const user = await Auth.currentAuthenticatedUser()
          const emailResponse = await api.verifyEmailAvailable(
            user?.attributes?.email || user?.email
          )
          if (emailResponse.status === "cognito-account") {
            Auth.signOut()
            dispatch({ type: LOGIN_FAILED })

            return navigate("/login", {
              state: {
                message:
                  "Your email was previously used to sign up with a password. Please provide your password to sign in.",
                email: user?.attributes?.email || user.email
              }
            })
          }

          if (emailResponse.status !== "ok" && type !== emailResponse.status) {
            Auth.signOut()
            dispatch({ type: LOGIN_FAILED })

            return navigate("/welcome-back", {
              state: {
                type: emailResponse.status,
                email: user?.attributes?.email || user.email
              }
            })
          }

          if (emailResponse.status === "ok") {
            switch (type) {
              case "google-account":
                await dispatch(googleFederatedSignup(loginUser))
                break
              case "microsoft-account":
                await dispatch(microsoftFederatedSignup(loginUser, team.team_id))
                // eslint-disable-next-line no-case-declarations
                const msProfile = await dispatch(loadProfile())
                // eslint-disable-next-line no-case-declarations
                const invite = {
                  user_email: msProfile.email,
                  team_id: team.team_id
                }
                await api.teams.handleTeamInviteDecision(team.team_id, {
                  accepted: true,
                  email: msProfile.email,
                  invite
                })
                break
              case "apple-account":
                // eslint-disable-next-line no-case-declarations
                const { appleId, givenName, familyName, email, name } = user
                await api.profile.save(
                  {
                    appleId,
                    source: "federated",
                    ...(givenName && { givenName }),
                    ...(familyName && { familyName }),
                    ...(name && { name }),
                    ...(email && { email: email.toLowerCase() }),
                    doesNotExist: false
                  },
                  false
                )
                break
              default:
                // eslint-disable-next-line no-console
                console.log(`type: ${type} not handled`)
                break
            }
          }
          // eslint-disable-next-line no-shadow
          const profile = await dispatch(loadProfile())
          dispatch({
            type: LOGIN,
            payload: {
              email: user?.attributes?.email || user.email,
              groups: []
            }
          })
          let destPath =
            type === "microsoft-account" ? "/dashboard" : "/introduce-yourself"
          let nextStep = ""
          const acceptedTosAt = profile.accepted_tos_at
          if (!acceptedTosAt) {
            nextStep = destPath
            destPath = acceptTosRoute
          }
          return navigate(destPath, {
            state: {
              givenName: user.givenName, nextStep
            }
          })
        }
        dispatch({
          type: LOGIN,
          payload: {
            email: profile.email,
            name: profile.name,
            groups: []
          }
        })
        setupErrorTracking(profile)
        return profile
      } catch (err) {
        if (err.message === "User is not confirmed.") {
          navigate(
            `/confirm-signup?email=${encodeURIComponent(
              emailParam
            )}&code=${codeParam}`,
            {
              state: {
                email: decodeURIComponent(emailParam),
                code: codeParam
              }
            }
          )
        }

        return dispatch({ type: LOGIN_FAILED })
      }
    }

export const login =
  (
    username,
    password,
    {
      code,
      redirectTo,
      firstLoginEver,
      // eslint-disable-next-line no-unused-vars
      profileUpdates,
      toastConfig,
      // eslint-disable-next-line no-unused-vars
      assignToTeam,
      navigate
    } = {}
  ) =>
    async (dispatch) => {
      try {
        await Auth.signIn(username, password)
        dispatch({
          type: LOGIN_REQUESTED,
          payload: {
            email: username
          }
        })
        const profile = await dispatch(loadProfile())
        const userInfo = await Auth.currentUserInfo()
        const session = await Auth.currentSession()
        if (typeof userInfo?.attributes?.email_verified !== "undefined") {
          const {
            attributes: { email_verified }
          } = userInfo
          if (!email_verified) {
            throw new Error(`User is not confirmed.`)
          }
        }
        if (profile) {
          setupErrorTracking(profile)
          dispatch({
            type: LOGIN,
            payload: {
              email: username,
              groups: session.accessToken.payload["cognito:groups"] || [],
              firstLoginEver,
              loginState: {
                toastConfig,
                code
              }
            }
          })
        }
        if (redirectTo?.pathname) {
          navigate(redirectTo.pathname, {
            state: {
              firstLoginEver
            }
          })
        }
        return { success: true }
      } catch (e) {
        let errorMsg = e.message ? e.message : e
        const doesNotExistMessages = [
          "User does not exist.",
          "UserMigration failed with error Bad password."
        ]
        if (errorMsg === "User is not confirmed.") {
          if (firstLoginEver) {
            await api.profile.sendEmailVerificationCode()
          }

          dispatch(loginConfirmCode(password, username))
          navigate(`/confirm-signup?email=${username}&code=${code}`, {
            state: {
              email: username,
              code,
              firstLoginEver
            }
          })
        } else if (doesNotExistMessages.includes(errorMsg)) {
          const emailResponse = await api
            .verifyEmailAvailable(username)
            .catch(() => ({}))
          if (
            emailResponse.status === "google-account" ||
            emailResponse.status === "apple-account"
          ) {
            errorMsg = `Email ${emailResponse.message}`
          } else {
            errorMsg = `Incorrect username or password`
          }
        }
        if (e.message === "Password attempts exceeded") {
          errorMsg = "Too many login attempts, try again later."
        } else {
          errorMsg = `Incorrect password`
        }
        dispatch({ type: LOGIN_FAILED, payload: errorMsg })

        return { success: false, message: errorMsg }
      }
    }

export const setLoginUI = () => dispatch => dispatch({ type: SET_LOGIN_UI })

export const unsetLoginUI = () => dispatch =>
  dispatch({ type: UNSET_LOGIN_UI })

export const logout =
  ({ navigate }) =>
    async (dispatch) => {
      analytics.reset()
      await Auth.signOut()
      queryClient.resetQueries()
      resetErrorTracking()
      const isMSSSO = sessionStorage.getItem('msal.account.keys')
      if (isMSSSO) {
        dispatch({
          type: RESET
        })
        localStorage.clear()
        sessionStorage.clear()
        navigate("/enterpriselogin")
        window.location.reload()
      } else {
        navigate("/login")
        dispatch(reset())
      }
    }

export const loginFail = errMsg => (dispatch) => {
  dispatch({ type: LOGIN_FAILED, payload: errMsg })
}

export const setFederatedInfo = federatedInfo => dispatch =>
  dispatch({ type: SET_FEDERATED_INFO, payload: federatedInfo })

export const setFederatedTimeout = timeout => dispatch =>
  dispatch({ type: SET_FEDERATED_TIMEOUT, payload: timeout })

export const setAuthError = (error, email) => dispatch =>
  dispatch({ type: SET_AUTH_ERROR, payload: { error, email } })
